import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import { ContactService } from './contact.service';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  FormData: FormGroup;
  sendingEmail: boolean;
  successResponse: boolean;
  failedResponse: boolean;
  constructor(private builder: FormBuilder, private contactService: ContactService) { }

  ngOnInit() {
    this.sendingEmail = false;
    this.successResponse = false;
    this.failedResponse = false;
    this.FormData = this.builder.group({
      name: new FormControl('', [Validators.required]),
      //email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      mobile: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),

    });
  }


  onSubmit(FormData) {
    this.sendingEmail = true;
    //Swal.fire('Please wait','wait','warning');
    console.log(FormData);
    this.contactService.sendEmail(FormData).subscribe((val) => {
      if (val) {
        console.log(val.message);
        if (val.message == 'success') {
          this.sendingEmail = false;
          this.successResponse = true;
          this.failedResponse = false;
        } else {
          this.sendingEmail = false;
          this.successResponse = false;
          this.failedResponse = true;
        }
        

        // Swal.fire({
        //   title: 'Status',
        //   text: 'Sent email to vedikmart Admin',
        //   type: 'success',
        //   showCloseButton: true,
        //   showCancelButton: true
        // });
        //Swal.fire('success', 'successfully sent', 'success');
      }
    });
  }
}