import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CartComponent } from './cart/cart.component';
import { CertificatesComponent } from './home/components/certificates/certificates.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AboutComponent } from './about/about.component';
import { ShopComponent } from './home/components/shop/shop.component';
import { PolicyComponent } from './policy/policy.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',     component: HomeComponent },
    { path: 'home/item-details/:itemId',     component: ItemDetailsComponent },
    { path: 'home/cart',     component: CartComponent },
    { path: 'home/certificates',     component: CertificatesComponent },
    { path: 'home/contact',     component: ContactFormComponent },
    { path: 'home/about',     component: AboutComponent },
    { path: 'home/shop',     component: ShopComponent },
    { path: 'home/policy',     component: PolicyComponent },
    
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
