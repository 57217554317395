import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css']
})
export class ItemDetailsComponent implements OnInit {
  public viewImage: number;
  qtyGroup: FormGroup;
  constructor(
    public fb: FormBuilder,
    private router: Router
  ) {
    this.viewImage = 1;
    this.qtyGroup = this.fb.group({
      qty: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  submitQty(){

  }

  buyNow(){
    this.router.navigateByUrl("/home/cart");
  }
}
