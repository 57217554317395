import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../../shared/log/logger.service';
import { environment } from '../../../../../environments/environment';
import { AbstractHttpService } from '../../../../shared/base/abstract-http';
import { SearchItems, ItemsDetails, AddToCart, Purchase } from '../model/shop.model';

@Injectable({
  providedIn: 'root'
})
export class ShopService extends AbstractHttpService {
  private _allCategories : string = environment.baseUrl + "ext/api/v1/categories";
  private _allSubCategories : string = environment.baseUrl + "ext/api/v1/subcategories";
  private _allProducts : string = environment.baseUrl + "ext/api/v1/products";
  private _allItems : string = environment.baseUrl + "ext/api/v1/items";
  private _itemImageGet : string = environment.baseUrl + "ext/api/v1/item/docs/";
  private _allItemsData : string = environment.baseUrl + "ext/api/v1/searchitems";


  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('ShopService', http, logger);
  };

  getAllCategoriesData(): Observable<any>{
    return this.http.get<any>(this._allCategories);
  }

  getAllSubCategoriesData(catId: any): Observable<any>{
    const url = this._allSubCategories + `?catId=${catId}`;
    return this.http.get<any>(url);
  }

  getAllProductsData(catId: any,subCatId: any): Observable<any>{
    const url = this._allProducts + `?catId=${catId}`+`&subCatId=${subCatId}`;
    return this.http.get<any>(url);
  }

  getAllItemsData(catId: any,subCatId: any,prodId: any): Observable<any>{
    const url = this._allItems + `?catId=${catId}`+`&subCatId=${subCatId}`+`&prodId=${prodId}`;
    return this.http.get<any>(url);
  }


  getItemImageUploadData(itemId: string): Observable<any>{
    const url = this._itemImageGet + `${itemId}`;
    return this.http.get<any>(url);
  }

  getAllItemsDataDetails(searchForm: SearchItems): Observable<any>{
    return this.http.post<ItemsDetails>(this._allItemsData, searchForm);
  }
}