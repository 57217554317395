import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AgmCoreModule } from '@agm/core';
import { HomeComponent } from './home.component';
import { ShopComponent } from './components/shop/shop.component';
import { RouterModule } from '@angular/router';
import { CartComponent } from 'app/cart/cart.component';
import { ItemDetailsComponent } from 'app/item-details/item-details.component';
import { HttpClientModule } from '@angular/common/http';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { ContactFormComponent } from 'app/contact-form/contact-form.component';
import { AboutComponent } from 'app/about/about.component';
import { PolicyComponent } from 'app/policy/policy.component';

@NgModule({
    declarations: [
        HomeComponent,
        ShopComponent,
        CartComponent,
        ItemDetailsComponent,
        CertificatesComponent,
        ContactFormComponent,
        AboutComponent,
        PolicyComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        NouisliderModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        JwBootstrapSwitchNg2Module,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }),
    ]
})
export class HomeModule { }
