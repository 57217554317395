import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../shared/log/logger.service';
import { environment } from '../../environments/environment';
import { AbstractHttpService } from '../shared/base/abstract-http';

@Injectable({
  providedIn: 'root'
})

export class ContactService extends AbstractHttpService {
  private _sendEmail : string = environment.baseUrl + "ext/api/v1/sendEmail";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('ContactService', http, logger);
  };


  sendEmail(formData: FormData): Observable<any>{
    return this.http.post<string>(this._sendEmail, formData);
  }


}
