import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  privacyExpanded: boolean = false;
  refundExpanded: boolean = false;
  termsExpanded: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.privacyExpanded = false;
    this.refundExpanded = false;
    this.termsExpanded = false;
  }

  togglePrivacy(): void {
    this.privacyExpanded = !this.privacyExpanded;
  }
  toggleRefund(): void {
    this.refundExpanded = !this.refundExpanded;
  }
  toggleTerms(): void {
    this.termsExpanded = !this.termsExpanded;
  }

}
